<template>
  <div class="col-12 text-center">
    <div v-if="show">
        <br><br><br>
        <img :src="require('@/assets/images/loading.svg')" alt=""><br>
        <i>جاري التحويل...</i>
    </div>
    <br><br><br>
    <a href="https://tahdir.net">نظام التحضير الذكي</a>
  </div>
</template>

<script>
export default {
    data(){
        return {
            show: true
        }
    },
    created(){
        var g = this;
        $(".chatbtna_a").remove()
      $.post(api + '/general/shortlink', {
        id: window.location.href.split("/s/")[1].split("#")[0]
      }).then(function(r){
          g.show = false
          if(r.status != 100){
            alert(r.response)
          }else{
            window.location = r.response[0] != '/' ? ('/' + r.response) : r.response
          }
      }).fail(function(){
          g.show = false
          alert("حدث خطأ")
      })
    }
}
</script>

<style>

</style>